.content-outer {
    align-items: center;
    display: flex;
    justify-content: center;
    max-width: 100vw;
    padding-top: 104px;
}

.content-outer.top {
    align-items: flex-start;
}

.content-inner {
    width: 100%;
    max-width: none !important;
}

@media only screen and (max-width: 768px) {
    .content-outer {
        align-items: flex-start;
    }

    .content-inner {
        width: 100%;
    }
}
@media only screen and (max-height: 500px) {
    .content-outer {
        align-items: flex-start;
    }
}
